@import "../../../../shared/scss/partials/variables"; 

.search-header-content {
    background-color: #BBC7D6;
}

.p-10 {
    padding: 10px;
}

.search-text {
    padding: 5px 15px;
    border-width: 0px;
}

.search-box {
    color: #71c5e8;
    input {
        border: transparent;
    }
    .form-control,
    .form-control:focus {
        font-weight: 400 !important;
        border-color: transparent !important;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .input-group {
        border: 1px solid #71c5e8;
        color: #71c5e8;
        background: white;
        padding: 0 8px 0 4px;
        width: 500px;
        border-radius: 0.25rem;
        .btn-outline-primary {
            border: transparent;
            background-color: white;
        }
        .btn-outline-primary:hover {
            color: black;
        }
        .btn-outline-primary:focus {
            border: transparent;
        }
    }
}

.clear-btn {
    background-color: #00263E !important;
}

.iframe-border {
    border: none !important;
}

.fs-20 {
    font-size: 20px;
}

.plus {
    padding: 0;
    list-style-type: none;
    font-weight: bold;
}
.logo-img {
    width: 80px;
    height: 80px;
}

.ft-navy {
    color: #00263E;
}

.contact-box {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.fs-17 {
    font-size: 17px;
}

.faq-btn {
    width: 100px;
}

@media (min-width: 210px) and (max-width: 780px) {
    .mobile-resp {
        flex-direction: column;
    }
    .mobile-btn-resp {
        margin-top: 15px;
    }
    .faq-btn {
        margin-left: 0px !important;
    }
    .btn-resp {
        margin-left: 25px !important;
    }
}
